<template>
  <BaseModal
    v-if="shownServiceCenter"
    :to-bottom="!xlAndLarger"
    content-transition="vfm-slide-up"
    :title="`Филиал ${shownServiceCenter.anchor}`"
  >
    <div :class="classes.wrapper">
      <div class="whitespace-pre-line mb-2">
        <p>{{ fullAddress }}</p>
        <p>{{ shownServiceCenter.work_times }}</p>
      </div>
      <BaseButton
        type="a"
        intent="link"
        underline="on"
        class="justify-center"
        :href="mapUrl"
        target="_blank"
      >
        Показать на карте
      </BaseButton>
      <img
        v-if="selectedImageSource"
        :class="classes.mainPhoto"
        :src="selectedImageSource"
        loading="lazy"
        :alt="fullAddress"
        @click="openLightbox()"
      >
      <div
        v-if="shownServiceCenter.images?.length"
        :class="classes.gallery"
      >
        <img
          v-for="(image, index) in shownServiceCenter.images"
          :key="image.id"
          :class="[
            classes.galleryItem,
            image.url === selectedImageSource ? classes.activeGalleryItem : '',
          ]"
          :src="image.url"
          loading="lazy"
          :alt="fullAddress"
          @click="setActiveImage(image.url, index)"
        >
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { cx } from 'cva'
import type { ServiceCenter } from '~/types/models'

const classes = {
  wrapper: cx(['xl:max-w-[684px]', 'p-4', 'xl:p-0', 'text-center']),
  mainPhoto: cx(['rounded-xl', 'mt-6 mb-[22px]', 'cursor-zoom-in']),
  gallery: cx(['flex', 'overflow-x-scroll']),
  galleryItem: cx([
    'w-[53px]',
    'h-[53px]',
    'p-[3px]',
    'cursor-pointer',
    'rounded-xl',
    'border-2',
    'border-solid',
    'border-white',
    'mr-[26px]',
    'last:mr-0',
  ]),
  activeGalleryItem: 'border-yellow-500',
}

const { $GLightbox } = useNuxtApp()

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const serviceCentersStore = useServiceCentersStore()
const { shownInModal: shownServiceCenter } = storeToRefs(serviceCentersStore)

const mapUrl = computed(() => {
  const latitude = shownServiceCenter.value?.latitude
  const longitude = shownServiceCenter.value?.longitude

  return `https://yandex.ru/maps/?rtext=~${latitude},${longitude}`
})

const fullAddress = computed(() =>
  shownServiceCenter.value
    ? `г. ${shownServiceCenter.value.city}, ${shownServiceCenter.value.address}`
    : '',
)

const activeImageSource = ref('')
const activeImageIndex = ref(0)
const setActiveImage = (source: string, index: number) => {
  activeImageSource.value = source
  activeImageIndex.value = index
}

const selectedImageSource = computed(() => activeImageSource.value || ((<ServiceCenter>shownServiceCenter.value).images?.length && (<ServiceCenter>shownServiceCenter.value).images[0].url))

const lightboxImages = computed(() => {
  if (!shownServiceCenter.value) return

  return shownServiceCenter.value.images.map((image) => {
    return {
      href: image.url,
    }
  })
})

const openLightbox = () => {
  const lightbox = $GLightbox({
    elements: lightboxImages.value,
    startAt: activeImageIndex.value,
  })

  lightbox.on(
    'slide_changed',
    ({ current }: { current: { slideIndex: number } }) => {
      setActiveImage(
        (<ServiceCenter>shownServiceCenter.value).images[current.slideIndex]
          .url,
        current.slideIndex,
      )
    },
  )

  lightbox.open()
}

watch(
  () => shownServiceCenter,
  () => {
    activeImageSource.value = ''
  },
  { deep: true },
)
</script>